import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '../../../store/actions';
import { WEB_URI } from '../../../services/helper';

const ProfileMenu = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.User);

    const [menu, setMenu] = useState(false);

    const toggle = () => {
        setMenu(!menu);
    }

    const handleLogout = () => {
        dispatch(userLogout());
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block user-dropdown">
                <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown" style={{ display: "flex", alignItems: "center" }}>
                    <img style={{ objectFit: "contain", background: "white", border: "1px solid grey" }} className="rounded-circle header-profile-user me-1" src={user?.user?.avatar?.url ? user?.user?.avatar?.url : "/favicon.ico"} alt="Header Avatar" />
                    <span className="d-none d-xl-inline-block ms-1 text-transform">{user?.user?.username ? user?.user?.username : "Admin"}</span>
                    <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem href={user?.user?.username ? `${WEB_URI}/${user?.user?.username}` : "#"}><i className="ri-user-line align-middle me-1"></i> {props.t('Profile')}</DropdownItem>
                    {/* <DropdownItem href="#"><i className="ri-wallet-2-line align-middle me-1"></i> {props.t('My Wallet')}</DropdownItem> */}
                    {/* <DropdownItem className="d-block" href="#"><span className="badge badge-success float-end mt-1">11</span><i className="ri-settings-2-line align-middle me-1"></i> {props.t('Settings')}</DropdownItem> */}
                    {/* <DropdownItem href="#"><i className="ri-lock-unlock-line align-middle me-1"></i> {props.t('Lock screen')}</DropdownItem> */}
                    <DropdownItem divider />
                    <DropdownItem className="text-danger" onClick={handleLogout}>
                        <i className="ri-shut-down-line align-middle me-1 text-danger"></i>
                        {props.t('Logout')}
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
}

export default withNamespaces()(ProfileMenu);
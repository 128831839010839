import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Card, CardBody } from 'reactstrap';
import { format } from 'date-fns';

const MiniWidgets = ({ navigate, reports }) => {
    const date = new Date();
    const formattedDate = format(date, 'do MMM yyyy');

    return (
        <React.Fragment>
            {
                reports.map((report, key) =>
                    <Col key={key} md={4}>
                        <Link to={navigate} className={`${navigate ? "cursor-pointer" : "cursor-default"}`}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-1 overflow-hidden">
                                            <h4 className="card-title mb-3">{report.title}</h4>
                                            <h4 className="mb-0">{report.value}</h4>
                                        </div>
                                        {/* <div className="text-primary">
                                        <i className={report.icon + " font-size-24"}></i>
                                    </div> */}
                                    </div>
                                </CardBody>

                                <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        {/* <span className="badge badge-soft-success font-size-11 me-1"><i className="mdi mdi-menu-up"> </i> {report.rate}</span> */}
                                        <p className="text-muted ms-2 ">
                                            <span>as of</span>
                                            <span className="badge badge-soft-success font-size-11 ms-1">{formattedDate}</span>
                                        </p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                )
            }
        </React.Fragment>
    );
}

export default MiniWidgets;
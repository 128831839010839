export const MINI_INFO_REQUEST = "MINI_INFO_REQUEST";
export const MINI_INFO_SUCCESS = "MINI_INFO_SUCCESS";
export const MINI_INFO_FAIL = "MINI_INFO_FAIL";

export const EZKARO_DATA_REQUEST = "EZKARO_DATA_REQUEST";
export const EZKARO_DATA_SUCCESS = "EZKARO_DATA_SUCCESS";
export const EZKARO_DATA_FAIL = "EZKARO_DATA_FAIL";

export const USER_DATA_REQUEST = "USER_DATA_REQUEST";
export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS";
export const USER_DATA_FAIL = "USER_DATA_FAIL";
import crypto from "crypto-js";
import { MAIN_URI } from '../../services/helper.js';
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOAD_REQUEST,
    USER_LOAD_SUCCESS,
    USER_LOAD_FAIL,
} from './constants';
import { toast } from "react-hot-toast";

const SECRET_KEY = "THIS_IS_THE_SECRET_KEY_FOR_EZ_KARO_THE_ONE_APP";

export const userLogin = (validID, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST,
        });

        const CustomHeader = new Headers();
        CustomHeader.append("Content-Type", "application/json");

        const config = {
            method: "POST",
            headers: CustomHeader,
            body: JSON.stringify({ validID, password }),
            redirect: "follow",
        };

        fetch(`${MAIN_URI}/admin/login`, config)
            .then((response) => response.json())
            .then((result) => {
                if (result.success === true) {
                    dispatch({
                        type: USER_LOGIN_SUCCESS,
                    });
                    toast.success(result.msg || result.message);
                    localStorage.setItem("auth_token", JSON.stringify(result.auth_token));
                }

                if (result.success === false) {
                    dispatch({
                        type: USER_LOGIN_FAIL,
                    });
                    toast.error(result.error);
                }
            })
            .catch((error) => {
                dispatch({
                    type: USER_LOGIN_FAIL,
                });
            });
    }
    catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
        });
    }
};

export const userLogout = () => async () => {
    localStorage.removeItem("auth_token");
    window.location.href = "/login";
};

export const loadUser = () => async (dispatch) => {
    try {
        if (localStorage.getItem("auth_token") === null || localStorage.getItem("auth_token") === "") return;

        dispatch({
            type: USER_LOAD_REQUEST,
        });

        const CustomHeader = new Headers();
        CustomHeader.append("ez_token", JSON.parse(localStorage.getItem("auth_token")));

        const config = {
            method: "GET",
            headers: CustomHeader,
            redirect: "follow",
        };

        fetch(`${MAIN_URI}/admin/load`, config)
            .then((response) => response.json())
            .then((result) => {
                if (result.success === true) {
                    const decryptedData = JSON.parse(
                        crypto.AES.decrypt(result.data, SECRET_KEY).toString(
                            crypto.enc.Utf8
                        )
                    );

                    dispatch({
                        type: USER_LOAD_SUCCESS,
                        payload: decryptedData,
                    });
                }

                if (result.success === false) {
                    dispatch({
                        type: USER_LOAD_FAIL,
                        payload: result.error,
                    });
                    localStorage.removeItem("auth_token");
                }
            })
            .catch((error) => {
                dispatch({
                    type: USER_LOAD_FAIL,
                    payload: error,
                });
                localStorage.removeItem("auth_token");
            });
    } catch (error) {
        dispatch({
            type: USER_LOAD_FAIL,
            payload: error,
        });
        localStorage.removeItem("auth_token");
    }
};
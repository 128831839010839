import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOAD_REQUEST,
    USER_LOAD_SUCCESS,
    USER_LOAD_FAIL,
} from './constants';

export const initialUserstate = {
    isLoggingIn: false,
    isAuthenticating: false,
    isAuthenticated: false,
    user: null,
}

const userReducer = (state = initialUserstate, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return {
                ...state,
                isLoggingIn: true,
            }
        case USER_LOGIN_SUCCESS:
            return {
                ...state,
                isLoggingIn: false,
            }
        case USER_LOGIN_FAIL:
            return {
                ...state,
                isLoggingIn: false,
            }

        case USER_LOAD_REQUEST:
            return {
                ...state,
                isAuthenticating: true,
                isAuthenticated: false,
                user: null,
            };
        case USER_LOAD_SUCCESS:
            return {
                ...state,
                isAuthenticating: false,
                isAuthenticated: true,
                user: action.payload,
            };
        case USER_LOAD_FAIL:
            return {
                ...state,
                isAuthenticating: false,
                isAuthenticated: false,
                user: null,
            };

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default userReducer;
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Button, Container, Label } from "reactstrap";
import { userLogin } from "../../store/user/actions";
import { toast, Toaster } from "react-hot-toast";

const Login = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.User);

  const [validId, setValidId] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validId) return toast.error("Enter valid Email/Phone/Username!");
    if (!password) return toast.error("Enter valid Password!");
    dispatch(userLogin(validId, password));
  };

  return (
    <React.Fragment>
      <Toaster toastOptions={{ duration: 5000 }} />
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <img
                            src="/Logo.png"
                            alt=""
                            className="auth-logo logo-dark mx-auto h-[175px]"
                          />

                          <h4 className="font-size-18">Welcome Back !</h4>
                          <p className="text-muted">
                            Sign in to continue to eZ - The One App
                          </p>
                        </div>
                        <div className="p-2">
                          <form
                            className="form-horizontal"
                            onSubmit={handleSubmit}
                          >
                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-user-2-line auti-custom-input-icon"></i>
                              <Label htmlFor="username">User ID</Label>
                              <input
                                name="username"
                                value={validId}
                                type="text"
                                className="form-control"
                                id="username"
                                placeholder="Enter Email/Phone/Username"
                                onChange={(e) => setValidId(e.target.value)}
                              />
                            </div>
                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-lock-2-line auti-custom-input-icon"></i>
                              <Label htmlFor="userpassword">Password</Label>
                              <input
                                name="password"
                                value={password}
                                type="password"
                                className="form-control"
                                id="userpassword"
                                placeholder="Enter password"
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                            {/* <div className="form-check">
                                                            <Input type="checkbox" className="form-check-input" id="customControlInline" />
                                                            <Label className="form-check-label" htmlFor="customControlInline">Remember me</Label>
                                                        </div> */}
                            <div className="mt-4 text-center">
                              <Button
                                color="primary"
                                className="w-md waves-effect waves-light text-green-500 font-extrabold"
                                type="submit"
                                disabled={
                                  user.isLoggingIn || user.isAuthenticating
                                }
                              >
                                {user.isLoggingIn || user.isAuthenticating
                                  ? "Verifying"
                                  : "Log In"}
                              </Button>
                            </div>
                            {/* <div className="mt-2 text-center">
                                                            <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</Link>
                                                        </div> */}
                          </form>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

import React from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";
import { useSelector } from 'react-redux';

const ServiceSales = () => {
    const dashboard = useSelector(state => state.Dashboard);

    const chartData = !dashboard.miniInfoLoading && dashboard.miniInfoLoaded
        ? {
            series: dashboard.miniInfo.services?.top?.length > 0 ? dashboard.miniInfo.services.top.map((item) => item.totalSold) : [],
            options: {
                labels: dashboard.miniInfo.services?.top?.length > 0 ? dashboard.miniInfo.services.top.map((item) => item.itemTitle) : [],
                plotOptions: {
                    pie: {
                        donut: {
                            size: '75%'
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false,
                },
                colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
            }
        }
        : {};

    const topServiceData = !dashboard.miniInfoLoading && dashboard.miniInfoLoaded && dashboard.miniInfo.services?.top?.length > 0
        ? dashboard.miniInfo.services.top
        : [];

    // const data = {
    //     series: [42, 26, 15, 10, 12, 11],
    //     options: {
    //         labels: ["Product A", "Product B", "Product C", "Product A", "Product B", "Product C"],
    //         plotOptions: {
    //             pie: {
    //                 donut: {
    //                     size: '75%'
    //                 }
    //             }
    //         },
    //         dataLabels: {
    //             enabled: false
    //         },
    //         legend: {
    //             show: false,
    //         },
    //         colors: ['#5664d2', '#1cbb8c', '#eeb902'],
    //     }
    // };

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    {/* <div className="float-end">
                        <select className="form-select form-select-sm">
                            <option defaultValue>Apr</option>
                            <option value="1">Mar</option>
                            <option value="2">Feb</option>
                            <option value="3">Jan</option>
                        </select>
                    </div> */}
                    <h4 className="card-title mb-4">Top Service Sales</h4>

                    <div id="donut-chart" className="apex-charts">
                        {!dashboard.miniInfoLoading && dashboard.miniInfoLoaded && dashboard.miniInfo.services?.top?.length > 0 && (
                            <ReactApexChart options={chartData?.options} series={chartData?.series} type="donut" height="250" />
                        )}
                    </div>

                    <Row>
                        {!dashboard.miniInfoLoading && dashboard.miniInfoLoaded && dashboard.miniInfo.services?.top?.length > 0 && (
                            topServiceData.map((item, i) => (
                                <Col xs={4} key={i}>
                                    <div className="text-center mt-4">
                                        <h5 className="text-truncate">
                                            <span style={{ color: 'GrayText' }}>{i + 1})</span> {item.itemTitle}
                                        </h5>
                                        <span className=''>{item._id}</span>
                                        <h5>{item.totalSold} sold</h5>
                                    </div>
                                </Col>
                            )))}
                        {/* <Col xs={4}>
                            <div className="text-center mt-4">
                                <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i> Product A</p>
                                <h5>42 %</h5>
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className="text-center mt-4">
                                <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-success font-size-10 me-1"></i> Product B</p>
                                <h5>26 %</h5>
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className="text-center mt-4">
                                <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-warning font-size-10 me-1"></i> Product C</p>
                                <h5>42 %</h5>
                            </div>
                        </Col> */}
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default ServiceSales;
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';

const ServiceAnalytics = () => {
    const dashboard = useSelector(state => state.Dashboard);

    const series = dashboard.miniInfo?.services?.top?.length > 0 ? dashboard.miniInfo.services.top.map((item) => item.totalSold) : [];
    const options = !dashboard.miniInfoLoading && dashboard.miniInfoLoaded
        ? {
            labels: dashboard.miniInfo?.services?.top?.length > 0 ? dashboard.miniInfo.services.top.map((item, i) => `${i + 1}) ${item.itemTitle}`) : [],
            colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'center',
                verticalAlign: 'middle',
                floating: false,
                fontSize: '14px',
                offsetX: 0,
                offsetY: -10
            },
            responsive: [{
                breakpoint: 600,
                options: {
                    chart: {
                        height: 240
                    },
                    legend: {
                        show: false
                    },
                }
            }]
        }
        : {};

    return (
        <React.Fragment>
            <ReactApexChart options={options} series={series} type="donut" height="320" />
        </React.Fragment>
    );
}

export default ServiceAnalytics;
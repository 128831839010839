const getDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);

    const date = dateTime.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    const time = dateTime.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    });

    const formattedDateTime = date + ', ' + time;
    return formattedDateTime;
}

export default getDateTime;
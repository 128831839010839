import React from 'react';
import { Spinner } from 'reactstrap';
import './Loader.scss';

const Loader = ({ type }) => {
    return (
        <div className="loading-container">
            <div className="spinner-frame">
                <Spinner
                    type={type}
                    color="success"
                    style={{ width: '3rem', height: '3rem' }}
                />
            </div>
        </div>
    )
}

export default Loader
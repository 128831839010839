import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Card, CardBody, Col } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getMiniInfo } from "../../store/dashboard/actions";
import Loader from "../../components/Loader/Loader";

import MiniWidgets from "./MiniWidgets";
import RegistrationAnalytics from "./RegistrationAnalytics";
import ActiveUsers from "./ActiveUsers";
// import LoginAnalytics from "./LoginAnalytics";
import ServiceAnalytics from "./ServiceAnalytics";
import ServiceSales from "./ServiceSales";
import EarningReports from "./EarningReports";
// import Sources from "./Sources";
// import RecentlyActivity from "./RecentlyActivity";
// import RevenueByLocations from "./RevenueByLocations";
// import ChatBox from "./ChatBox";
// import LatestTransactions from "./LatestTransactions";
import "./dashboard.scss";

const Dashboard = () => {
    const dispatch = useDispatch();
    const dashboard = useSelector(state => state.Dashboard);

    const breadcrumbItems = [
        { title: "Dashboard", link: "#" }
    ];

    const totalUsersData = !dashboard.miniInfoLoading && dashboard.miniInfoLoaded ? [
        { title: "Total Buyers", value: dashboard.miniInfo.users.totalUsers - dashboard.miniInfo.users.totalSellers },
        { title: "Total Sellers", value: dashboard.miniInfo.users.totalSellers },
    ] : [];
    const loginDifferentiation = !dashboard.miniInfoLoading && dashboard.miniInfoLoaded ? [
        { title: "Phone Users", value: dashboard.miniInfo.users.phoneUsers },
        { title: "Google Users", value: dashboard.miniInfo.users.googleUsers },
        { title: "LinkedIn Users", value: dashboard.miniInfo.users.linkedinUsers },
    ] : [];
    const servicesData = !dashboard.miniInfoLoading && dashboard.miniInfoLoaded ? [
        { title: "Total Services", value: dashboard.miniInfo.services.total },
        { title: "Active Services", value: dashboard.miniInfo.services.total },
    ] : [];
    const revenueServiceData = !dashboard.miniInfoLoading && dashboard.miniInfoLoaded ? [
        { title: "Revenue per Service (in $)", value: `$ ${dashboard.miniInfo.revenue.averagePerService.dollars}` },
        { title: "Revenue per Service (in ₹)", value: `₹ ${dashboard.miniInfo.revenue.averagePerService.rupees}` },
    ] : [];
    const totalRevenue = !dashboard.miniInfoLoading && dashboard.miniInfoLoaded ? [
        { title: "Total Revenue (in $)", value: `$ ${dashboard.miniInfo.bookings.revenue.dollars}` },
        { title: "Total Revenue (in ₹)", value: `₹ ${dashboard.miniInfo.bookings.revenue.rupees}` },
    ] : [];
    const revenuePerUser = !dashboard.miniInfoLoading && dashboard.miniInfoLoaded ? [
        { title: "Revenue per User (in $)", value: `$ ${dashboard.miniInfo.revenue.averagePerUser.dollars}` },
        { title: "Revenue per User (in ₹)", value: `₹ ${dashboard.miniInfo.revenue.averagePerUser.rupees}` },
    ] : [];
    const revenuePerTransaction = !dashboard.miniInfoLoading && dashboard.miniInfoLoaded ? [
        { title: "Revenue per Transaction (in $)", value: `$ ${dashboard.miniInfo.revenue.averagePerBookings.dollars}` },
        { title: "Revenue per Transaction (in ₹)", value: `₹ ${dashboard.miniInfo.revenue.averagePerBookings.rupees}` },
    ] : [];

    useEffect(() => {
        dispatch(getMiniInfo());
    }, [dispatch]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {dashboard.miniInfoLoading && !dashboard.miniInfoLoaded ? (
                        <Loader />
                    ) : (
                        <>
                            <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} />
                            <Row>
                                <Col xl={8}>
                                    <Row xl={2}>
                                        <MiniWidgets navigate="/users" reports={[{ title: "Registered Users / Total Users", value: dashboard?.miniInfo?.users?.totalUsers }]} />
                                    </Row>
                                    <Row xl={2}>
                                        <MiniWidgets reports={totalUsersData} />
                                    </Row>
                                    <Row>
                                        <MiniWidgets reports={loginDifferentiation} />
                                    </Row>
                                    <RegistrationAnalytics />
                                </Col>
                                <Col xl={4}>
                                    <ActiveUsers />
                                    <EarningReports title="Revenue (in ₹)" currencyCode="₹"
                                        week={dashboard?.miniInfo?.bookings?.revenue?.rupee?.week} month={dashboard?.miniInfo?.bookings?.revenue?.rupee?.month}
                                    />
                                    <EarningReports title="Revenue (in $)" currencyCode="$"
                                        week={dashboard?.miniInfo?.bookings?.revenue?.dollar?.week} month={dashboard?.miniInfo?.bookings?.revenue?.dollar?.month}
                                    />
                                    <Row xl={1}>
                                        <MiniWidgets reports={[{ title: "Service sales per Seller", value: dashboard?.miniInfo?.bookings?.salesPerSeller }]} />
                                    </Row>
                                </Col>
                                {/* <LoginAnalytics /> */}
                            </Row>

                            {/* <Row>
                                <MiniWidgets reports={servicesData} />
                            </Row> */}

                            <Row>
                                <Col xl={8}>
                                    <Row xl={2}>
                                        <MiniWidgets reports={servicesData} />
                                    </Row>
                                    <Row xl={2}>
                                        <MiniWidgets reports={totalRevenue} />
                                    </Row>
                                    <Row xl={2}>
                                        <MiniWidgets reports={revenueServiceData} />
                                    </Row>
                                    <Row xl={2}>
                                        <MiniWidgets reports={revenuePerUser} />
                                    </Row>
                                    <Row xl={2}>
                                        <MiniWidgets reports={revenuePerTransaction} />
                                    </Row>
                                </Col>
                                <Col xl={4}>
                                    <div className="service-analytics">
                                        <Card>
                                            <CardBody>
                                                <h4 className="card-title mb-4">Top Service Analytics</h4>
                                                <ServiceAnalytics />
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <ServiceSales />
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col>
                                    <div className="service-analytics">
                                        <Card>
                                            <CardBody>
                                                <h4 className="card-title mb-4">Service Analytics</h4>
                                                <ServiceAnalytics />
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>
                                <Col>
                                    <ServiceSales />
                                </Col>
                            </Row> */}

                            {/* <Row>
                                <Sources />
                                <RecentlyActivity />
                                <RevenueByLocations />
                            </Row> */}

                            {/* <Row>
                                <ChatBox />
                                <LatestTransactions />
                            </Row> */}
                        </>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Dashboard;
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const AppRoute = ({ component: Component, layout: Layout, isAuthProtected, isAuthenticated, ...rest }) => (
	<Route
		{...rest}
		render={props => {
			if (isAuthProtected && !isAuthenticated) {
				// if the route requires authentication and user is not authenticated, redirect to '/login'
				return <Redirect to='/login' />;
			} else if (!isAuthProtected && isAuthenticated) {
				// if the route does not require authentication and user is authenticated, redirect to '/dashboard'
				return <Redirect to='/dashboard' />;
			} else {
				// else, just render the component with the layout
				return (
					<Layout>
						<Component {...props} />
					</Layout>
				);
			}
		}}
	/>
);

export default AppRoute;
import React, { useState, useEffect } from "react";
import { Switch, BrowserRouter as Router, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

import "./assets/scss/theme.scss";

import fakeBackend from './helpers/AuthType/fakeBackend';
import { loadUser } from "./store/actions";

fakeBackend();

const App = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const layout = useSelector(state => state.Layout);
    const [Layout, setLayout] = useState(VerticalLayout);
    useEffect(() => {
        let layoutCls = VerticalLayout;
        switch (layout.layoutType) {
            case "horizontal":
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        setLayout(layoutCls);
    }, [layout]);

    const user = useSelector(state => state.User);
    const authToken = localStorage.getItem("auth_token");
    useEffect(() => {
        if (authToken) {
            dispatch(loadUser());
        }
    }, [authToken, dispatch]);
    useEffect(() => {
        if (user.isAuthenticated) {
            history.push("/dashboard");
        }
    }, [user.isAuthenticated, history]);

    return (
        <React.Fragment>
            <Router>
                <Switch>
                    {publicRoutes.map((route, idx) => (
                        <AppRoute
                            path={route.path}
                            layout={NonAuthLayout}
                            component={route.component}
                            key={idx}
                            isAuthProtected={false}
                            isAuthenticated={user.isAuthenticated}
                        />
                    ))}

                    {authProtectedRoutes.map((route, idx) => (
                        <AppRoute
                            path={route.path}
                            layout={Layout}
                            component={route.component}
                            key={idx}
                            isAuthProtected={true}
                            isAuthenticated={user.isAuthenticated}
                        />
                    ))}
                </Switch>
            </Router>
        </React.Fragment>
    );
}

export default App;
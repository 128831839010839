import {
    MINI_INFO_REQUEST,
    MINI_INFO_SUCCESS,
    MINI_INFO_FAIL,
    EZKARO_DATA_REQUEST,
    EZKARO_DATA_SUCCESS,
    EZKARO_DATA_FAIL,
    USER_DATA_REQUEST,
    USER_DATA_SUCCESS,
    USER_DATA_FAIL,
} from './constants';

export const initialDashboardstate = {
    miniInfoLoading: false,
    miniInfoLoaded: false,
    miniInfo: null,
    ezKaroLoading: false,
    eZKaroLoaded: false,
    eZKaro: null,
    usersLoading: false,
    usersLoaded: false,
    users: null,
}

const dashboardReducer = (state = initialDashboardstate, action) => {
    switch (action.type) {
        case MINI_INFO_REQUEST:
            return {
                ...state,
                miniInfoLoading: true,
                miniInfoLoaded: false,
                miniInfo: null,
            };
        case MINI_INFO_SUCCESS:
            return {
                ...state,
                miniInfoLoading: false,
                miniInfoLoaded: true,
                miniInfo: action.payload,
            };
        case MINI_INFO_FAIL:
            return {
                ...state,
                miniInfoLoading: false,
                miniInfoLoaded: false,
                miniInfo: null,
            };

        case EZKARO_DATA_REQUEST:
            return {
                ...state,
                ezKaroLoading: true,
                eZKaroLoaded: false,
                eZKaro: null,
            };
        case EZKARO_DATA_SUCCESS:
            return {
                ...state,
                ezKaroLoading: false,
                eZKaroLoaded: true,
                eZKaro: action.payload,
            };
        case EZKARO_DATA_FAIL:
            return {
                ...state,
                ezKaroLoading: false,
                eZKaroLoaded: false,
                eZKaro: null,
            };

        case USER_DATA_REQUEST:
            return {
                ...state,
                usersLoading: true,
                usersLoaded: false,
                users: null,
            };
        case USER_DATA_SUCCESS:
            return {
                ...state,
                usersLoading: false,
                usersLoaded: true,
                users: action.payload,
            };
        case USER_DATA_FAIL:
            return {
                ...state,
                usersLoading: false,
                usersLoaded: false,
                users: null,
            };

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default dashboardReducer;
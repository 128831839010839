import React from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";
import { useSelector } from 'react-redux';

const ActiveUsers = () => {
    const dashboard = useSelector(state => state.Dashboard);
    const data = !dashboard.miniInfoLoading && dashboard.miniInfoLoaded ? dashboard.miniInfo.users : {};

    const state = !dashboard.miniInfoLoading && dashboard.miniInfoLoaded && {
        series: [data?.active?.today, data?.active?.week, data?.active?.month],
        options: {
            labels: ["Today", "This Week", "This Month"],
            plotOptions: {
                pie: {
                    donut: {
                        size: '75%'
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: true,
            },
            colors: ['#5664d2', '#1cbb8c', '#eeb902'],
        }
    };

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">Active Users</h4>

                    {!dashboard.miniInfoLoading && dashboard.miniInfoLoaded && (
                        <div id="donut-chart" className="apex-charts">
                            <ReactApexChart options={state?.options} series={state?.series} type="donut" height="250" />
                        </div>
                    )}

                    <Row>
                        <Col xs={4}>
                            <div className="text-center mt-4">
                                <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i>Today</p>
                                <h5>{data?.active?.today}</h5>
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className="text-center mt-4">
                                <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-success font-size-10 me-1"></i>This Week</p>
                                <h5>{data?.active?.week}</h5>
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className="text-center mt-4">
                                <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-warning font-size-10 me-1"></i>This Month</p>
                                <h5>{data?.active?.month}</h5>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default ActiveUsers;
import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication Module
import User from "./user/reducer";
import Dashboard from "./dashboard/reducer";
import Account from './auth/register/reducer';
import Forget from './auth/forgetpwd/reducer';

//Calendar 
import Calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer"

//ecommerce
import Ecommerce from "./e-commerce/reducer";

const rootReducer = combineReducers({

    // public
    Layout,

    // Authentication
    User,
    Account,
    Forget,
    Calendar,
    Ecommerce,
    chat,

    // Dashboard
    Dashboard
});

export default rootReducer;
import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

//Import Charts
import { RegistrationAnalyticsChart } from './Charts'
import "./dashboard.scss";
import { useSelector } from 'react-redux';

const RegistrationAnalytics = () => {
    const dashboard = useSelector(state => state.Dashboard);
    const data = !dashboard.miniInfoLoading && dashboard.miniInfoLoaded ? dashboard.miniInfo.users : {};

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-2">Registration Analytics</h4>
                    <div id="line-column-chart" className="apex-charts" dir="ltr">
                        <RegistrationAnalyticsChart />
                    </div>
                </CardBody>

                <CardBody className="border-top text-center">
                    <Row>
                        <Col sm={4}>
                            <div className="d-inline-flex">
                                <h5 className="me-2">{data?.registration?.today}</h5>
                            </div>
                            <p className="text-muted text-truncate mb-0">Today</p>
                        </Col>

                        <Col sm={4}>
                            <div className="mt-4 mt-sm-0">
                                <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i> This Week :</p>
                                <div className="d-inline-flex">
                                    <h5 className="mb-0 me-2">{data?.registration?.week}</h5>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className="mt-4 mt-sm-0">
                                <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-success font-size-10 me-1"></i> This Month :</p>
                                <div className="d-inline-flex">
                                    <h5 className="mb-0">{data?.registration?.month}</h5>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default RegistrationAnalytics;
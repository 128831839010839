import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";

const EarningReports = ({ title, currencyCode, week, month }) => {
    const [weekday, setWeekday] = useState('');
    useEffect(() => {
        const currentDate = new Date();
        const options = { weekday: 'long' };
        const weekdayName = new Intl.DateTimeFormat('en-US', options).format(currentDate);
        setWeekday(weekdayName);
    }, []);

    const [currentMonth, setCurrentMonth] = useState('');
    useEffect(() => {
        const currentDate = new Date();
        const options = { month: 'long' };
        const monthName = currentDate.toLocaleString('en-US', options);
        setCurrentMonth(monthName);
    }, []);

    const [weekPercentage, setWeekPercentage] = useState(0);
    const [monthPercentage, setMonthPercentage] = useState(0);
    useEffect(() => {
        const date = new Date();
        const currentDayOfWeek = date.getDay();
        const currentDayOfMonth = date.getDate();
        const currentMonth = date.getMonth();
        const currentYear = date.getFullYear();
        const weekPercent = ((currentDayOfWeek + 1) / 7) * 100;
        setWeekPercentage(weekPercent.toFixed(2));
        const daysInMonth = getDaysInMonth(currentMonth, currentYear);
        const monthPercent = (currentDayOfMonth / daysInMonth) * 100;
        setMonthPercentage(monthPercent.toFixed(2));
    }, []);

    const series = [weekPercentage];
    const options = {
        labels: [weekday],
        chart: {
            sparkline: {
                enabled: true
            }
        },
        dataLabels: {
            enabled: true
        },
        // legend: {
        //     show: true,
        // },
        colors: ['#5664d2'],
        stroke: {
            lineCap: 'round'
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: '70%'
                },
                track: {
                    margin: 0,
                },
                dataLabels: {
                    show: false
                }
            }
        }
    };

    const series2 = [monthPercentage];
    const options2 = {
        labels: [currentMonth],
        chart: {
            sparkline: {
                enabled: true
            }
        },
        dataLabels: {
            enabled: true
        },
        // legend: {
        //     show: true,
        // },
        colors: ['#1cbb8c'],
        stroke: {
            lineCap: 'round'
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: '70%'
                },
                track: {
                    margin: 0,
                },
                dataLabels: {
                    show: false
                }
            }
        }
    };

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    {/* <Dropdown className="float-end" isOpen={menu} toggle={() => setMenu(!menu)} >
                        <DropdownToggle tag="i" className="arrow-none card-drop" >
                            <i className="mdi mdi-dots-vertical"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem href="">Sales Report</DropdownItem>
                            <DropdownItem href="">Export Report</DropdownItem>
                            <DropdownItem href="">Profit</DropdownItem>
                            <DropdownItem href="">Action</DropdownItem>
                        </DropdownMenu>
                    </Dropdown> */}
                    <h4 className="card-title mb-4">{title}</h4>
                    <div className="text-center">
                        <Row>
                            <Col sm={6}>
                                <div>
                                    <div className="mb-3">
                                        <div id="radialchart-1" className="apex-charts">
                                            Weekly Earnings
                                            <ReactApexChart options={options} series={series} type="radialBar" height="60" />
                                        </div>
                                    </div>
                                    <p className="text-muted text-truncate mb-2">{weekday}</p>
                                    <h5 className='mb-0'>{currencyCode}{week}</h5>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="mt-5 mt-sm-0">
                                    <div className="mb-3">
                                        <div id="radialchart-2" className="apex-charts">
                                            Monthly Earnings
                                            <ReactApexChart options={options2} series={series2} type="radialBar" height="60" />
                                        </div>
                                    </div>
                                    <p className="text-muted text-truncate mb-2">{currentMonth}</p>
                                    <h5 className='mb-0'>{currencyCode}{month}</h5>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

function getDaysInMonth(month, year) {
    return new Date(year, month + 1, 0).getDate();
}

export default EarningReports;
import React, { useEffect, useMemo } from 'react';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Container, Card, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getEzKaroData } from '../../store/dashboard/actions';
import Loader from '../../components/Loader/Loader';
import { WEB_URI } from '../../services/helper';
import EzKaroContainer from '../../components/Common/EzKaroContainer';
import getDateTime from '../../util/getDateTime';

const EzKaro = () => {
    const dispatch = useDispatch();
    const dashboard = useSelector(state => state.Dashboard);

    useEffect(() => {
        dispatch(getEzKaroData());
    }, [dispatch]);

    const columns = useMemo(
        () => [
            {
                Header: "Date",
                accessor: "date",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Domain",
                accessor: "domain",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Category",
                accessor: "category",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Description",
                accessor: "description",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Budget",
                accessor: "budget",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Delivery",
                accessor: "delivery",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "User",
                accessor: "username",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Name",
                accessor: "name",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell }) => cell.row.values.username
                    ? (
                        <a href={`${WEB_URI}/${cell.row.values.username}`} className="text-green-500 hover:text-green-600" target="_blank" rel="noopener noreferrer">
                            {cell.row.values.name}
                        </a>
                    )
                    : cell.row.values.name
            },
            {
                Header: "Email",
                accessor: "email",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Phone",
                accessor: "phone",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Uploads",
                accessor: "file",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell }) => cell.row.values.file
                    ? (
                        <a href={cell.row.values.file} className="text-green-500 hover:text-green-600" target="_blank" rel="noopener noreferrer">
                            View File
                        </a>
                    )
                    : "None"
            },
        ],
        []
    );

    const eZKaroData = !dashboard.ezKaroLoading && dashboard.eZKaroLoaded && dashboard.eZKaro.length > 0
        ? dashboard.eZKaro.map((item, i) => ({
            id: i + 1,
            date: getDateTime(item.created_on),
            domain: item.domain,
            category: item.category,
            description: item.description,
            budget: `${item.currency} ${item.amount}`,
            delivery: item.deliveryTime,
            ...item.bookedBy?.username && {
                username: item.bookedBy.username,
            },
            name: item.bookedBy.name,
            email: item.bookedBy.email,
            phone: `+${item.bookedBy.code} ${item.bookedBy.phone}`,
            ...item?.file && {
                file: item.file
            },
        }))
        : [];

    const breadcrumbItems = [
        { title: "Dashboard", link: "/" },
        { title: "eZ Karo", link: "#" }
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {dashboard.ezKaroLoading && !dashboard.eZKaroLoaded ? (
                        <Loader />
                    ) : (
                        <>
                            <Breadcrumbs
                                title="eZ Karo"
                                breadcrumbItems={breadcrumbItems}
                            />
                            <Card>
                                <CardBody>
                                    <EzKaroContainer
                                        columns={columns || []}
                                        data={eZKaroData || []}
                                        isPagination={false}
                                        iscustomPageSize={false}
                                        isBordered={false}
                                        customPageSize={10}
                                    />
                                </CardBody>
                            </Card>
                        </>
                    )}
                </Container>
            </div>
        </React.Fragment>
    )
}

export default EzKaro
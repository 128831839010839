import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getUsersData } from '../../store/dashboard/actions';
import { Card, CardBody, Container } from 'reactstrap';
import Loader from '../../components/Loader/Loader';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import UsersContainer from '../../components/Common/UsersContainer';
import { WEB_URI } from '../../services/helper';
import getDateTime from '../../util/getDateTime';

const Users = () => {
  const dispatch = useDispatch();
  const dashboard = useSelector(state => state.Dashboard);

  useEffect(() => {
    dispatch(getUsersData());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "User",
        accessor: "username",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Name",
        accessor: "name",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell }) => cell.row.values.username
          ? (
            <a href={`${WEB_URI}/${cell.row.values.username}`} className="text-green-500 hover:text-green-600" target="_blank" rel="noopener noreferrer">
              {cell.row.values.name}
            </a>
          )
          : cell.row.values.name
      },
      {
        Header: "Phone",
        accessor: "phone",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Email",
        accessor: "email",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Role",
        accessor: "role",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "DOB",
        accessor: "dob",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Gender",
        accessor: "gender",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Job Role",
        accessor: "jobRole",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Location",
        accessor: "location",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Last Active",
        accessor: "lastActive",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Joined On",
        accessor: "joinedOn",
        disableFilters: true,
        filterable: false,
      },
    ],
    []
  );

  const usersData = !dashboard.usersLoading && dashboard.usersLoaded && dashboard.users.length > 0
    ? dashboard.users.map((item, i) => ({
      id: i + 1,
      username: item.username,
      name: `${item.firstName} ${item?.lastName}`,
      phone: item.phone || "-",
      email: item.email || "-",
      role: item.role.charAt(0).toUpperCase() + item.role.slice(1),
      dob: item.dob ? `${item?.dob?.date}/${item?.dob?.month}/${item?.dob?.year}` : "-",
      gender: item.gender || "-",
      jobRole: item.profileCategory.jobRoles[0] || "-",
      location: item.location ? `${item.location.city || ""} ${item.location.state || ""}, ${item.location.country || ""} ` : "-",
      lastActive: item.lastActive ? getDateTime(item.lastActive) : "-",
      joinedOn: item.joinedOn ? getDateTime(item.joinedOn) : "-"
    }))
    : [];

  const breadcrumbItems = [
    { title: "Dashboard", link: "/" },
    { title: "Users", link: "#" },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {dashboard.usersLoading && !dashboard.usersLoaded ? (
            <Loader />
          ) : (
            <>
              <Breadcrumbs
                title="Users"
                breadcrumbItems={breadcrumbItems}
              />
              <Card>
                <CardBody>
                  <UsersContainer
                    columns={columns || []}
                    data={usersData || []}
                    isPagination={false}
                    iscustomPageSize={false}
                    isBordered={false}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Users
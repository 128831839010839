import crypto from "crypto-js";
import { MAIN_URI } from '../../services/helper.js';
import {
    MINI_INFO_REQUEST,
    MINI_INFO_SUCCESS,
    MINI_INFO_FAIL,
    EZKARO_DATA_REQUEST,
    EZKARO_DATA_SUCCESS,
    EZKARO_DATA_FAIL,
    USER_DATA_REQUEST,
    USER_DATA_SUCCESS,
    USER_DATA_FAIL
} from './constants';
// import { toast } from "react-hot-toast";

const SECRET_KEY = "THIS_IS_THE_SECRET_KEY_FOR_EZ_KARO_THE_ONE_APP";

export const getMiniInfo = () => async (dispatch) => {
    try {
        dispatch({
            type: MINI_INFO_REQUEST,
        });

        const CustomHeader = new Headers();
        CustomHeader.append("ez_token", JSON.parse(localStorage.getItem("auth_token")));

        const config = {
            method: "GET",
            headers: CustomHeader,
            redirect: "follow",
        };

        fetch(`${MAIN_URI}/admin/get-minimal-info`, config)
            .then((response) => response.json())
            .then((result) => {
                if (result.success === true) {
                    const decryptedData = JSON.parse(
                        crypto.AES.decrypt(result.info, SECRET_KEY).toString(
                            crypto.enc.Utf8
                        )
                    );

                    dispatch({
                        type: MINI_INFO_SUCCESS,
                        payload: decryptedData,
                    });
                }

                if (result.success === false) {
                    dispatch({
                        type: MINI_INFO_FAIL,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: MINI_INFO_FAIL,
                });
            });
    } catch (error) {
        dispatch({
            type: MINI_INFO_FAIL,
        });
        console.log(error);
    }
};

export const getEzKaroData = () => async (dispatch) => {
    try {
        dispatch({
            type: EZKARO_DATA_REQUEST,
        });

        const CustomHeader = new Headers();
        CustomHeader.append("ez_token", JSON.parse(localStorage.getItem("auth_token")));

        const config = {
            method: "GET",
            headers: CustomHeader,
            redirect: "follow",
        };

        fetch(`${MAIN_URI}/admin/get-ez-karo`, config)
            .then((response) => response.json())
            .then((result) => {
                if (result.success === true) {
                    const decryptedData = JSON.parse(
                        crypto.AES.decrypt(result.data, SECRET_KEY).toString(
                            crypto.enc.Utf8
                        )
                    );

                    dispatch({
                        type: EZKARO_DATA_SUCCESS,
                        payload: decryptedData,
                    });
                }

                if (result.success === false) {
                    dispatch({
                        type: EZKARO_DATA_FAIL,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: EZKARO_DATA_FAIL,
                });
            });
    } catch (error) {
        dispatch({
            type: EZKARO_DATA_FAIL,
        });
        console.log(error);
    }
};

export const getUsersData = () => async (dispatch) => {
    try {
        dispatch({
            type: USER_DATA_REQUEST,
        });

        const CustomHeader = new Headers();
        CustomHeader.append("ez_token", JSON.parse(localStorage.getItem("auth_token")));

        const config = {
            method: "GET",
            headers: CustomHeader,
            redirect: "follow",
        };

        fetch(`${MAIN_URI}/admin/get-users-info`, config)
            .then((response) => response.json())
            .then((result) => {
                if (result.success === true) {
                    const decryptedData = JSON.parse(
                        crypto.AES.decrypt(result.data, SECRET_KEY).toString(
                            crypto.enc.Utf8
                        )
                    );

                    dispatch({
                        type: USER_DATA_SUCCESS,
                        payload: decryptedData,
                    });
                }

                if (result.success === false) {
                    dispatch({
                        type: USER_DATA_FAIL,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: USER_DATA_FAIL,
                });
            });
    } catch (error) {
        dispatch({
            type: USER_DATA_FAIL,
        });
        console.log(error);
    }
};